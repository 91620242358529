<template>
	<div class="userInfo-share">
		<!-- 消费记录模块 -->
	</div>
</template>

<script>
	export default{
		name:"UserInfoShare",
		data(){
			return{
				
			}
		}
	}
</script>

<style lang="less" scoped>
.userInfo-share {
	
}
</style>
